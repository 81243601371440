import React, { useEffect, useState } from 'react';
import { fetchOffers } from './api';
import { ListGroup, Card, Container, Row, Col } from 'react-bootstrap';

function Offers() {
    const [offers, setOffers] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchOffers()
            .then(data => setOffers(data))
            .catch(err => setError("Failed to fetch offers"));
    }, []);

    if (error) {
        return <div className="alert alert-danger">{error}</div>;
    }

    return (
        <Container>
            <h1 className="my-4">Available Offers</h1>
            <ListGroup>
                {offers.length ? (
                    offers.map(offer => (
                        <ListGroup.Item key={offer.id} className="mb-3">
                            <Card>
                                <Card.Body>
                                    <Row>
                                        <Col md={4}>
                                            {offer.images && offer.images.length > 0 ? (
                                                <img
                                                    src={offer.images[0]}
                                                    alt={`Offer ${offer.id}`}
                                                    className="img-fluid rounded"
                                                />
                                            ) : (
                                                <p>No image available</p>
                                            )}
                                        </Col>
                                        <Col md={8}>
                                            <Card.Title>{offer.name}</Card.Title>
                                            <Card.Text>{offer.description}</Card.Text>
                                            <a href={offer.affiliate_link} className="btn btn-primary" target="_blank" rel="noopener noreferrer">
                                                View Offer
                                            </a>
                                            <p className="mt-2">
                                                <strong>Commission Rate:</strong> {offer.commission_rate}%
                                            </p>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </ListGroup.Item>
                    ))
                ) : (
                    <p>No offers available</p>
                )}
            </ListGroup>
        </Container>
    );
}

export default Offers;
