import React from 'react';
import Offers from './Offers';

function App() {
    return (
        <div>            <Offers />
        </div>
    );
}

export default App;